import "./App.css";
import Routes from './routes'
import AOS from "aos";
import "aos/dist/aos.css";
import Maintenance from '../src/views/Maintenance'

AOS.init();
  function App() {
      const maintenance = false;
  return (
    <div className="apptest">
        {maintenance ? (
                <Maintenance />
            ) : (
            <Routes/>
        )}
   </div>
  );
}

export default App;
